import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import '@/assets/scss/app.scss';
import '@/plugins/bootstrap.bundle.min.js'
import 'animate.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Carousel3d from 'vue-carousel-3d';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { VueSelect } from "vue-select";
// import VueScrollmagic from 'vue-scrollmagic'
import VueSweetalert2 from 'vue-sweetalert2';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
const globalOptions = {
  mode: 'international',
};

import VueAos from 'vue-aos'
import vueScrollto from 'vue-scrollto'
// import VueAnalytics from 'vue-analytics';
import moment from 'moment'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(VueAos)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Carousel3d)
Vue.use(CoolLightBox)
Vue.use(VueSelect)
Vue.use(VueSweetalert2)
Vue.use(VueTelInput, globalOptions)
Vue.use(vueScrollto)
/* Vue.use(VueAnalytics, {
  id: 'UA-288364506-1',
  router
}) */
Vue.use(VueGtag, {
  config: {
      id: 'G-85W3WY1ZZK',
  }
}, router)
Vue.use(moment)

// Vue.use(VueScrollmagic)


new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    AOS.init()
  }
}).$mount('#app')
