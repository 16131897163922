import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';

Vue.use(VueRouter)

function SaveFileTypeToDownload(params){
  store.commit('setFileToDownload', params.file)
}

const routes = [
  { path: '*', beforeEnter: (to, from, next) => { next('/404') } },
  {
      path: '/404',
      name: '404',
      component: () => import(/* webpackChunkName: "404" */'../views/pages/404.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/pages/Home.vue')
  },
  {
    path: '/preescolar',
    name: 'preescolar',
    component: () => import(/* webpackChunkName: "preescolar" */ '../views/pages/Preescolar.vue')
  },
  {
    path: '/primaria',
    name: 'primaria',
    component: () => import(/* webpackChunkName: "primaria" */ '../views/pages/Primaria.vue')
  },
  {
    path: '/secundaria',
    name: 'secundaria',
    component: () => import(/* webpackChunkName: "secundaria" */ '../views/pages/Secundaria.vue')
  },
  {
    path: '/bachillerato',
    name: 'bachillerato',
    component: () => import(/* webpackChunkName: "bachillerato" */ '../views/pages/Preparatoria.vue')
  },
  {
    path: '/acerca-de-nosotros',
    name: 'acerca-de-nosotros',
    component: () => import(/* webpackChunkName: "acerca de nosotros" */ '../views/pages/SobreNosotros.vue')
  },
  {
    path: '/admisiones',
    name: 'admisiones',
    component: () => import(/* webpackChunkName: "contacto" */ '../components/contacto/Admisiones.vue')
  },
  {
    path: '/bolsa-de-trabajo',
    name: 'bolsa-de-trabajo',
    component: () => import(/* webpackChunkName: "contacto" */ '../components/contacto/BolsaDeTrabajo.vue')
  },
  {
    path: '/directorio-escolar',
    name: 'directorio-escolar',
    component: () => import(/* webpackChunkName: "contacto" */ '../components/contacto/DirectorioEscolar.vue')
  },
  {
    path: '/eventos',
    name: 'eventos',
    component: () => import(/* webpackChunkName: "eventos" */ '../views/pages/Eventos.vue')
  },
  {
    path: '/galeria',
    name: 'galeria',
    component: () => import(/* webpackChunkName: "galeria" */ '../views/pages/ComingSoon.vue')
  },
  {
    path: '/politicas-de-privacidad',
    name: 'politicas-de-privacidad',
    component: () => import(/* webpackChunkName: "politicas de privacidad" */ '../views/pages/ComingSoon.vue')
  },
  {
    path: '/plataformas',
    name: 'plataformas',
    component: () => import(/* webpackChunkName: "politicas de privacidad" */ '../views/pages/Plataformas.vue')
  },
  {
    path: '/ingles',
    name: 'ingles',
    component: () => import(/* webpackChunkName: "Inglés" */ '../views/pages/Ingles.vue')
  },
  {
    path: '/deportes',
    name: 'deportes',
    component: () => import(/* webpackChunkName: "Deportes" */ '../views/pages/Deportes.vue')
  },
  {
    path: '/pastoral',
    name: 'pastoral',
    component: () => import(/* webpackChunkName: "Pastoral" */ '../views/pages/Pastoral.vue')
  },
  {
    path: '/coe',
    name: 'coe',
    component: () => import(/* webpackChunkName: "COE" */ '../views/pages/COE.vue')
  },
  {
    path: '/solicitud-nuevo-ingreso',
    name: 'solicitud-nuevo-ingreso',
    component: () => import(/* webpackChunkName: "COE" */ '../views/pages/SolicitudNuevoIngreso.vue') 
  },
  {
    path: '/directorio',
    name: 'directorio',
    component: () => import(/* webpackChunkName: "Directorio" */ '../views/pages/Directorio.vue') 
  },
  {
    path: "/info/:file?",
    name: "info",
    component: () => import(/* webpackChunkName: "Descargas" */ "../views/qr/Qr.vue"),
    meta: {
      downloadFile: true,
      title: 'Descargas QR ',
      metaTags: [
        {
          name: 'Home',
          content: 'The home page of our app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our app.'
        }
      ]
    }
  },
  {
    path: '/enviar-documentos',
    name: 'enviar-documentos',
    component: () => import(/* webpackChunkName: "COE" */ '../views/pages/SendDocumentos.vue') 
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.hash) {
      return { x: 0, y: 0, behavior: 'smooth', }    
    }
      
  }
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  // const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if(to.meta.downloadFile) {
    SaveFileTypeToDownload(to.params)
    if (to.params.file != 'preescolar' && to.params.file != 'primaria' && to.params.file != 'secundaria' && to.params.file != 'preparatoria'){
      nearestWithTitle.meta.title = 'Descarga fallida';
    } else {
      nearestWithTitle.meta.title = nearestWithTitle.meta.title + to.params.file;
    }
  }
  
  
  /* if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if(!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag)); */
  
  next()
})

export default router
