<template>
  <div id="app">
    <router-link to="/" style="display: inline;">
      <div class="ContainerLogo ContainerLogoAnimated" id="ContainerLogo" style="z-index: 9999;">
        <img class="sizeLogo" id="sizeLogo" src="@/assets/images/home-renderizado/logo_blanco_Jassa_200px.webp" alt="" style="height: 84px;">
      </div>
    </router-link>
    <router-view/>
    <ScrollToTop />
  </div>
</template>

<script>
    export default {
        components: {
            ScrollToTop: () => import('@/components/footer/ScrollToTop')
        },
        mounted(){
          window.addEventListener("scroll", this.handleScroll)
        },
        data() {
          return {
            lastScrollTop: 0,
          }
        },
        methods: {
          handleScroll() {
            var st = window.pageYOffset || document.documentElement.scrollTop;
            let containerlogo = document.getElementById("ContainerLogo")
            let sizelogo = document.getElementById("sizeLogo")
            // let menuButton = document.getElementById("MenuButton")
            if (st > this.lastScrollTop) {
              containerlogo.classList.remove('ContainerLogoAnimated')
              sizelogo.classList.add('sizeLogoAnimated')
              // menuButton.classList.add('MenuButtonAnimated')
            }
            else if (st < this.lastScrollTop) {
              containerlogo.classList.add('ContainerLogoAnimated')
              sizelogo.classList.remove('sizeLogoAnimated')
              // menuButton.classList.remove('MenuButtonAnimated')
            }  
            this.lastScrollTop = st <= 0 ? 0 : st;
            /*let scrollBtn = document.getElementById("circle");
            if (window.scrollY > 0) {
                scrollBtn.classList.remove("invisible");
            } else {
                scrollBtn.classList.add("invisible");
            } */
          },
          openMenuToggle(e) {
            let arrow = document.querySelectorAll(".arrow");
            arrow.forEach(element => {
              element.parentElement.parentElement.classList.remove("showMenu");
            });
            e.target.classList.add("showMenu")
          },
        }
    }
</script>

<style>
@import '@/assets/css/app.css';
@import '@/assets/fonts/JassaFonts.css';

/* @media(max-width: 500px) {

    .shape-group{
        display: block !important;
    }

} */
</style>
