export default {
    getFilterSection(state) {
      return state.filterSection;
    },
    getBackdrop(state) {
      return state.backdrop;
    },
    getFileToDownload(state) {
      return state.fileToDownload
    },
    getwindowWidth(state) {
      return state.windowWidth
    }
  };