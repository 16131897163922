export default {
    toggleFilterSection(state) {
      state.filterSection = !state.filterSection;
    },
    toggleBackdrop(state) {
      state.backdrop = !state.backdrop;
    },
    setFileToDownload(state, fileToDownload) {
      state.fileToDownload = fileToDownload
    },
    setwindowWidth(state, windowWidth) {
      state.windowWidth = windowWidth
    }
  };
  